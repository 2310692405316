.posts-collapse .post-content {
  margin-bottom: $posts-collapse-margin;
  margin-left: $posts-collapse-margin;
  position: relative;

  +mobile() {
    margin-left: $posts-collapse-margin-mobile;
    margin-right: $posts-collapse-margin-mobile;
  }

  .collection-title {
    font-size: $font-size-large;
    position: relative;

    &::before {
      background: $grey-dark;
      border: 1px solid white;
      margin-left: -6px;
      margin-top: -4px;
      position: absolute;
      top: 50%;
      round-icon(10px);
    }
  }

  .collection-year {
    font-size: $font-size-largest;
    font-weight: bold;
    margin: 60px 0;
    position: relative;

    &::before {
      background: $grey;
      margin-left: -4px;
      margin-top: -4px;
      position: absolute;
      top: 50%;
      round-icon(8px);
    }
  }

  .collection-header {
    display: block;
    margin-left: 20px;

    small {
      color: $grey;
      margin-left: 5px;
    }
  }

  .post-header {
    border-bottom: 1px dashed $grey-light;
    // 2px is equal to half the width of .posts-collapse::before
    margin: 30px 2px 0;
    padding-left: 15px;
    position: relative;
    transition: border $transition-ease;

    &::before {
      background: $grey;
      border: 1px solid white;
      left: -6px;
      position: absolute;
      top: $font-size-smallest;
      transition: background $transition-ease;
      round-icon(6px);
    }

    &:hover {
      border-bottom-color: $grey-dim;

      &::before {
        background: $black-deep;
      }
    }
  }

  .post-meta-container {
    display: inline;
    font-size: $font-size-smallest;
    margin-right: 10px;
  }

  .post-title {
    display: inline;

    a {
      border-bottom: 0;
      color: var(--link-color);
    }

    .fa-external-link-alt {
      font-size: $font-size-small;
      margin-left: 5px;
    }
  }

  &::before {
    background: $whitesmoke;
    content: ' ';
    height: 100%;
    margin-left: -2px;
    position: absolute;
    // To do: 1.25em is inaccurate when .collection-title has line breaks on mobile
    top: 1.25em;
    width: 4px;
  }
}
