.highlight:hover .copy-btn, pre:hover .copy-btn {
  opacity: 1;
}

figure.highlight {
  .table-container {
    position: relative;
  }
}

.copy-btn {
  color: $black-dim;
  cursor: pointer;
  line-height: 1.6;
  opacity: 0;
  padding: 2px 6px;
  position: absolute;
  transition: opacity $transition-ease;

  if (hexo-config('codeblock.copy_button.style') == 'flat') {
    background: white;
    border: 0;
    font-size: $font-size-smaller;
    right: 0;
    top: 0;
  } else if (hexo-config('codeblock.copy_button.style') == 'mac') {
    color: var(--highlight-foreground);
    font-size: 14px;
    right: 0;
    top: 2px;
  } else {
    background-color: $gainsboro;
    background-image: linear-gradient(#fcfcfc, $gainsboro);
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    font-size: $font-size-smaller;
    right: 4px;
    top: 8px;
  }
}

if (hexo-config('codeblock.copy_button.style') == 'mac') {
  figure.highlight {
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .4);
    padding-top: 30px;

    .table-container {
      border-radius: 0 0 5px 5px;
    }

    &::before {
      background: #fc625d;
      box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
      left: 12px;
      margin-top: -20px;
      position: absolute;
      round-icon(12px);
    }
  }
}
