if (hexo-config('github_banner.enable')) {
  @keyframes octocat-wave {
    0%, 100% {
      transform: rotate(0);
    }

    20%, 60% {
      transform: rotate(-25deg);
    }

    40%, 80% {
      transform: rotate(10deg);
    }
  }

  .github-corner {
    :hover .octo-arm {
      animation: octocat-wave 560ms ease-in-out;
    }

    svg {
      color: white;
      fill: var(--theme-color);
      position: absolute;
      right: 0;
      top: 0;
      z-index: $zindex-0;
    }

    +tablet-mobile() {
      if (hexo-config('local_search.enable') or hexo-config('algolia_search.enable')) {
        display: none;
      }

      svg {
        if (($scheme == 'Pisces') or ($scheme == 'Gemini')) {
          color: var(--theme-color);
          fill: white;
        }
      }

      .github-corner:hover .octo-arm {
        animation: none;
      }

      .github-corner .octo-arm {
        animation: octocat-wave 560ms ease-in-out;
      }
    }

    if ($scheme == 'Mist') {
      +mobile() {
        svg {
          top: inherit;
        }
      }
    }
  }
}
