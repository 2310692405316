.sub-menu {
  margin: 0;
  padding: 6px 0;

  .menu-item {
    display: inline-block;

    a {
      background: transparent;
      margin: 5px 10px;
      padding: initial;

      &:hover {
        background: transparent;
        color: $sidebar-highlight;
      }
    }
  }

  .menu-item-active {
    border-bottom-color: $sidebar-highlight;
    color: $sidebar-highlight;

    &:hover {
      border-bottom-color: $sidebar-highlight;
    }
  }
}
