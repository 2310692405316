if (hexo-config('follow_me')) {
  .followme {
    color: $grey;
    padding: 1em 1.5em;
    text-align: center;
    post-card();

    .social-list {
      flex-wrap();

      .social-item {
        margin: .5em 2em;
        position: relative;

        +tablet-mobile() {
          margin: .5em .75em;
        }
      }

      .social-link {
        border: 0;
        // Make the hit area continious
        display: block;

        .icon {
          font-size: 1.75em;
        }

        .label {
          display: block;
          font-size: 14px;
        }

        &:hover + .social-item-img {
          display: block;
        }
      }

      span.social-link {
        color: var(--link-color);

        &:hover {
          color: var(--link-hover-color);
        }
      }

      .social-item-img {
        display: none;
        left: 50%;
        max-width: $post-reward-img-width;
        position: absolute;
        transform: translate(-50%, 20px);
      }
    }
  }
}
