// Flexbox layout makes it possible to reorder the child
// elements of .post-footer through the `order` CSS property
// Fix issue #16
// To do: use `gap` instead of `margin`
// See https://caniuse.com/flexbox-gap
.post-footer {
  flex-column();
}

.post-eof {
  background: $grey-light;
  height: 1px;
  margin: $post-eof-margin-top auto $post-eof-margin-bottom;
  width: 8%;

  .post-block:last-of-type & {
    display: none;
  }
}

if (hexo-config('creative_commons.post')) {
  .post-copyright ul {
    list-style: none;
    overflow: hidden;
    padding: .5em 1em;
    position: relative;
    post-card();

    &::after {
      content: '\f25e';
      font-family: 'Font Awesome 6 Brands';
      font-size: 200px;
      opacity: .1;
      position: absolute;
      right: -50px;
      top: -150px;
    }
  }
}

.post-tags {
  margin-top: 40px;
  text-align: $scheme-text-align;

  a {
    display: inline-block;
    font-size: $font-size-smaller;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
