.category-all-page {
  .category-all-title {
    text-align: center;
  }

  .category-all {
    margin-top: 20px;
  }

  .category-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .category-list-item {
    margin: 5px 10px;
  }

  .category-list-count {
    color: $grey;

    &::before {
      content: ' (';
    }

    &::after {
      content: ') ';
    }
  }

  .category-list-child {
    padding-left: 10px;
  }
}
