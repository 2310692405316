.toggle {
  line-height: 0;

  .toggle-line {
    background: white;
    display: block;
    height: 2px;
    left: 0;
    position: relative;
    top: 0;
    transition: all .4s;
    width: 100%;

    &:first-child {
      margin-top: 1px;
    }

    &:not(:first-child) {
      margin-top: 4px;
    }
  }
}

.toggle.toggle-arrow {
  toggle-arrow(hexo-config('sidebar.position'));
}

.toggle.toggle-close {
  toggle-close(hexo-config('sidebar.position'));
}
