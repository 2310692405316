// Blockquote with all children centered.
.blockquote-center {
  border-left: 0;
  margin: 40px 0;
  padding: 0;
  position: relative;
  text-align: center;

  &::before, &::after {
    left: 0;
    line-height: 1;
    opacity: .6;
    position: absolute;
    width: 100%;
  }

  &::before {
    border-top: 1px solid $grey-light;
    text-align: left;
    top: -20px;
    font-family-icons('\f10d');
  }

  &::after {
    border-bottom: 1px solid $grey-light;
    bottom: -20px;
    text-align: right;
    font-family-icons('\f10e');
  }

  p, div {
    text-align: center;
  }
}
