::selection {
  background: $selection-bg;
  color: $selection-color;
}

html, body {
  height: 100%;
}

body {
  background: var(--body-bg-color);
  box-sizing: border-box;
  color: var(--text-color);
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  min-height: 100%;
  position: relative;
  transition: padding $transition-ease;

  if (hexo-config('body_scrollbar.overlay')) {
    overflow-x: hidden;
    @supports (overflow-x: clip) {
      overflow-x: clip;
    }
    width: 100vw;
  }

  if (hexo-config('body_scrollbar.stable')) {
    overflow-y: scroll;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-headings;
  font-weight: bold;
  line-height: 1.5;
  margin: 30px 0 15px;
}

for $headline in (1 .. 6) {
  h{$headline} {
    font-size: $font-size-headings-base - $font-size-headings-step * $headline;
  }
}

p {
  margin: 0 0 20px;
}

a {
  border-bottom: 1px solid $link-decoration-color;
  color: var(--link-color);
  // For a:not(:any-link)
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  word-wrap();

  &:hover {
    border-bottom-color: var(--link-hover-color);
    color: var(--link-hover-color);
  }
}

iframe, img, video, embed {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

hr {
  background-image: repeating-linear-gradient(-45deg, $grey-lighter, $grey-lighter 4px, transparent 4px, transparent 8px);
  border: 0;
  height: 3px;
  margin: 40px 0;
}

blockquote {
  border-left: 4px solid $grey-lighter;
  color: var(--blockquote-color);
  margin: 0;
  padding: 0 15px;

  cite::before {
    content: '-';
    padding: 0 5px;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin: 0;
  padding: 0;
}
