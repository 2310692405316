.site-nav {
  +tablet() {
    site-nav-hide-by-default();
  }
}

.menu .menu-item {
  display: block;
  margin: 0;

  a {
    padding: 5px 20px;
    position: relative;
    text-align: left;
    transition-property: background-color;
  }

  +tablet-mobile() {
    &.menu-item-search {
      display: none;
    }
  }

  .badge {
    background: $grey-light;
    border-radius: 10px;
    color: var(--content-bg-color);
    float: right;
    padding: 2px 5px;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .1);
  }
}

if (not hexo-config('menu_settings.badges')) {
  .main-menu .menu-item-active::after {
    background: $grey;
    border-radius: 50%;
    content: ' ';
    height: 6px;
    margin-top: -3px;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 6px;
  }
}
