.post-nav {
  border-top: 1px solid $gainsboro;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 1em;
  padding: 10px 5px 0;
}

.post-nav-item {
  flex: 1;

  a {
    border-bottom: 0;
    display: block;
    font-size: $font-size-small;
    line-height: 1.6;

    &:active {
      top: 2px;
    }
  }

  .fa {
    font-size: $font-size-smallest;
  }

  &:first-child {
    .fa {
      margin-right: 5px;
    }
  }

  &:last-child {
    text-align: right;

    .fa {
      margin-left: 5px;
    }
  }
}
