.post-gallery {
  display: flex;
  min-height: 200px;

  .post-gallery-image {
    flex: 1;

    &:not(:first-child) {
      clip-path: polygon(40px 0, 100% 0, 100% 100%, 0 100%);
      margin-left: -20px;
    }

    &:not(:last-child) {
      margin-right: -20px;
    }

    img {
      height: 100%;
      object-fit: cover;
      // Override darkmode image opacity.
      opacity: 1;
      width: 100%;
    }
  }
}

.posts-expand .post-gallery {
  margin-bottom: 60px;
}

.posts-collapse .post-gallery {
  margin: 15px 0;
}
