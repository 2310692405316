.site-nav-toggle, .site-nav-right {
  display: none;

  +mobile() {
    flex-column();
  }

  .toggle {
    color: var(--text-color);
    padding: 10px;
    width: 22px;

    .toggle-line {
      background: var(--text-color);
      border-radius: 1px;
    }
  }
}

.site-nav {
  +mobile() {
    site-nav-hide-by-default();
  }
}
