.btn {
  background: var(--btn-default-bg);
  border: 2px solid var(--btn-default-border-color);
  border-radius: $btn-default-radius;
  color: var(--btn-default-color);
  display: inline-block;
  font-size: $font-size-small;
  line-height: 2;
  padding: 0 20px;
  transition: background-color $transition-ease;

  &:hover {
    background: var(--btn-default-hover-bg);
    border-color: var(--btn-default-hover-border-color);
    color: var(--btn-default-hover-color);
  }

  + .btn {
    margin: 0 0 8px 8px;
  }

  .fa-fw {
    text-align: left;
    width: (18em / 14);
  }
}
