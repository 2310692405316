.tag-cloud {
  text-align: center;

  a {
    display: inline-block;
    margin: 10px;
  }
}

for $tag-cloud in (0 .. 10) {
  $tag-cloud-color = mix($tag-cloud-end, $tag-cloud-start, $tag-cloud * 10);
  .tag-cloud-{$tag-cloud} {
    border-bottom-color: $tag-cloud-color;
    color: $tag-cloud-color;
  }
}

if (hexo-config('darkmode')) {
  @media (prefers-color-scheme: dark) {
    for $tag-cloud in (0 .. 10) {
      $tag-cloud-color = mix($tag-cloud-end-dark, $tag-cloud-start-dark, $tag-cloud * 10);
      .tag-cloud-{$tag-cloud} {
        border-bottom-color: $tag-cloud-color;
        color: $tag-cloud-color;
      }
    }
  }
}
