.reward-container {
  margin: $post-card-margin;
  padding: 1em 0;
  text-align: center;

  button {
    button($sidebar-highlight);
    border: 2px solid $sidebar-highlight;
    border-radius: 2px;
    outline: 0;
    transition: all $transition-ease;
    vertical-align: text-top;
  }
}

.post-reward {
  display: none;
  padding-top: 20px;

  &.active {
    display: block;
  }

  div {
    display: inline-block;

    span {
      display: block;
    }

    if (hexo-config('reward_settings.animation')) {
      &:hover span {
        animation: next-roll .1s infinite linear;
        // The animation may affect :hover of img in dark mode
        pointer-events: none;
      }
    }
  }

  img {
    display: inline-block;
    margin: .8em 2em 0;
    max-width: 100%;
    width: $post-reward-img-width;
  }
}

@keyframes next-roll {
  from {
    transform: rotateZ(30deg);
  }

  to {
    transform: rotateZ(-30deg);
  }
}
