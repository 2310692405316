// Header Section
// --------------------------------------------------
.headband {
  background: $headband-bg;
  height: $headband-height;

  +tablet-mobile() {
    display: none;
  }
}

.site-brand-container {
  display: flex;
  flex-shrink: 0;
  padding: 0 10px;
}

.use-motion {
  .column, .site-brand-container .toggle {
    opacity: 0;
  }
}

@import 'site-meta';
@import 'site-nav';
@import 'menu';

@import 'bookmark';
@import 'github-banner';
