.header {
  background: var(--content-bg-color);
  border-radius: $border-radius-inner;
  box-shadow: $box-shadow-inner;

  +tablet-mobile() {
    border-radius: initial;
  }
}

.main {
  // Make sure that .header and .main-inner are the same height
  // Required for .sidebar `position: sticky;`
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  main-container();

  if (hexo-config('sidebar.position') == 'right') {
    flex-direction: row-reverse;
  }

  +tablet-mobile() {
    display: block;
    width: auto;
  }
}

.main-inner {
  border-radius: $border-radius-inner;
  box-sizing: border-box;
  width: $content-wrap;

  +tablet-mobile() {
    border-radius: initial;
    width: 100%;
  }
}

.footer-inner {
  if (hexo-config('sidebar.position') == 'right') {
    padding-right: $sidebar-desktop + $sidebar-offset;
  } else {
    padding-left: $sidebar-desktop + $sidebar-offset;
  }

  +tablet-mobile() {
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }
}
