// Sidebar Navigation
.sidebar-nav {
  display: none;
  margin: 0;
  padding-bottom: 20px;
  padding-left: 0;

  .sidebar-nav-active & {
    display: block;
  }

  li {
    border-bottom: 1px solid transparent;
    color: $sidebar-nav-color;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-small;

    &.sidebar-nav-overview {
      margin-left: 10px;
    }

    &:hover {
      color: $sidebar-nav-hover-color;
    }
  }
}

.sidebar-toc-active .sidebar-nav-toc, .sidebar-overview-active .sidebar-nav-overview {
  border-bottom-color: $sidebar-highlight;
  color: $sidebar-highlight;

  &:hover {
    color: $sidebar-highlight;
  }
}

// Need for Sidebar/TOC inner scrolling if content taller then viewport.
.sidebar-panel-container {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-panel {
  display: none;
}

.sidebar-overview-active .site-overview-wrap {
  // Flexbox layout makes it possible to reorder the child
  // elements of .site-overview-wrap through the `order` CSS property
  flex-column();
  gap: 10px;
}

.sidebar-toc-active .post-toc-wrap {
  display: block;
}
