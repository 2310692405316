:root {
  --body-bg-color: $body-bg-color;
  --content-bg-color: $content-bg-color;
  --card-bg-color: $card-bg-color;
  --text-color: $text-color;
  --blockquote-color: $blockquote-color;
  --link-color: $link-color;
  --link-hover-color: $link-hover-color;
  --brand-color: $brand-color;
  --brand-hover-color: $brand-hover-color;
  --table-row-odd-bg-color: $table-row-odd-bg-color;
  --table-row-hover-bg-color: $table-row-hover-bg-color;
  --menu-item-bg-color: $menu-item-bg-color;
  --theme-color: $theme-color;

  --btn-default-bg: $btn-default-bg;
  --btn-default-color: $btn-default-color;
  --btn-default-border-color: $btn-default-border-color;
  --btn-default-hover-bg: $btn-default-hover-bg;
  --btn-default-hover-color: $btn-default-hover-color;
  --btn-default-hover-border-color: $btn-default-hover-border-color;

  --highlight-background: $highlight-background;
  --highlight-foreground: $highlight-foreground;
  --highlight-gutter-background: $highlight-gutter-background;
  --highlight-gutter-foreground: $highlight-gutter-foreground;

  color-scheme: light;
}

if (hexo-config('darkmode')) {
  @media (prefers-color-scheme: dark) {
    :root {
      --body-bg-color: $body-bg-color-dark;
      --content-bg-color: $content-bg-color-dark;
      --card-bg-color: $card-bg-color-dark;
      --text-color: $text-color-dark;
      --blockquote-color: $blockquote-color-dark;
      --link-color: $link-color-dark;
      --link-hover-color: $link-hover-color-dark;
      --brand-color: $brand-color-dark;
      --brand-hover-color: $brand-hover-color-dark;
      --table-row-odd-bg-color: $table-row-odd-bg-color-dark;
      --table-row-hover-bg-color: $table-row-hover-bg-color-dark;
      --menu-item-bg-color: $menu-item-bg-color-dark;
      --theme-color: $theme-color-dark;

      --btn-default-bg: $btn-default-bg-dark;
      --btn-default-color: $btn-default-color-dark;
      --btn-default-border-color: $btn-default-border-color-dark;
      --btn-default-hover-bg: $btn-default-hover-bg-dark;
      --btn-default-hover-color: $btn-default-hover-color-dark;
      --btn-default-hover-border-color: $btn-default-hover-border-color-dark;

      --highlight-background: $highlight-background-dark;
      --highlight-foreground: $highlight-foreground-dark;
      --highlight-gutter-background: $highlight-gutter-background-dark;
      --highlight-gutter-foreground: $highlight-gutter-foreground-dark;

      color-scheme: dark;
    }

    img {
      opacity: .75;

      &:hover {
        opacity: .9;
      }
    }

    iframe {
      color-scheme: light;
    }
  }
}
