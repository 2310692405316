// Menu
// --------------------------------------------------
.menu {
  margin: 0;
  padding: 1em 0;
  text-align: center;
}

.menu-item {
  display: inline-block;
  list-style: none;
  margin: 0 10px;

  +mobile() {
    display: block;
    margin-top: 10px;

    &.menu-item-search {
      display: none;
    }
  }

  a {
    border-bottom: 0;
    display: block;
    font-size: $font-size-smaller;
    transition: border-color $transition-ease;

    &:hover, &.menu-item-active {
      background: var(--menu-item-bg-color);
    }
  }

  .fa, .fab, .far, .fas {
    margin-right: 8px;
  }

  .badge {
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    margin-left: .35em;
    margin-top: .35em;
    text-align: center;
    white-space: nowrap;

    +mobile() {
      float: right;
      margin-left: 0;
    }
  }
}

if (hexo-config('motion.transition.menu_item')) {
  .use-motion .menu-item {
    visibility: hidden;
  }
}
