.group-picture {
  margin-bottom: 20px;

  .group-picture-row {
    display: flex;
    gap: 3px;
    margin-bottom: 3px;
  }

  .group-picture-column {
    flex: 1;

    img {
      height: 100%;
      margin: 0;
      object-fit: cover;
      width: 100%;
    }
  }
}
