@import 'disqusjs';
@import 'gitalk';
@import 'utterances';
@import 'search';
@import 'math';
@import 'gitter';

.use-motion .animated {
  // Fix issue #48 #55
  animation-fill-mode: none;
  // Fix issue #46 .animated in .sidebar
  visibility: inherit;
}

.use-motion .sidebar .animated {
  animation-fill-mode: both;
}
