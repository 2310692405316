.links-of-blogroll {
  font-size: $font-size-smaller;
}

.links-of-blogroll-title {
  font-size: $font-size-small;
  font-weight: 600;
}

.links-of-blogroll-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
