.site-meta {
  flex-grow: 1;
  text-align: center;

  +mobile() {
    text-align: center;
  }
}

.custom-logo-image {
  margin-top: 20px;

  +tablet-mobile() {
    display: none;
  }
}

.brand {
  border-bottom: 0;
  color: var(--brand-color);
  display: inline-block;
  padding: $brand-padding;

  &:hover {
    color: var(--brand-hover-color);
  }
}

.site-title {
  font-family: $font-family-logo;
  font-size: $font-size-title;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}

.site-subtitle {
  color: $subtitle-color;
  font-size: $font-size-subtitle;
  margin: $site-subtitle-margin;
}

.use-motion {
  .site-title, .site-subtitle, .custom-logo-image {
    opacity: 0;
    position: relative;
    top: -10px;
  }
}
