if (hexo-config('gitalk.enable')) {
  .gt-header a, .gt-comments a, .gt-popup a {
    border-bottom: 0;
  }

  .gt-container .gt-popup .gt-action.is--active::before {
    top: .7em;
  }

  if (hexo-config('darkmode')) {
    @media (prefers-color-scheme: dark) {
      .gt-container .gt-header-textarea {
        background-color: var(--card-bg-color) !important;
      }
    }
  }
}
